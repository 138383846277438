<template>
  <div class="com-home-QuestionOne">
    <img
      class="img0"
      src="../../../../public/img/QuestionSolution/QuestionOne/底纹.png"
    />
    <img
      class="img1"
      src="../../../../public/img/QuestionSolution/QuestionOne/主文案@2x.png"
    />
    <img
      class="img2"
      src="../../../../public/img/QuestionSolution/QuestionOne/手机.png"
    />
    <img
      class="img3"
      src="../../../../public/img/QuestionSolution/QuestionOne/编组 2@2x.png"
    />
    <img
      class="img4"
      src="../../../../public/img/QuestionSolution/QuestionOne/悬浮一 功能入口@2x.png"
    />
    <img
      class="img5"
      src="../../../../public/img/QuestionSolution/QuestionOne/悬浮二 我的报销@2x.png"
    />
    <img
      class="img6"
      src="../../../../public/img/QuestionSolution/QuestionOne/文案二@2x.png"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      this.$el.style.visibility = "visible";
      this.api.animateCSS(this.$el.querySelector(".img1"), "fadeInUp");
      this.api.animateCSS(this.$el.querySelector(".img2"), "zoomIn");
      this.api.animateCSS(this.$el.querySelector(".img3"), "fadeInLeft");
      this.api.animateCSS(this.$el.querySelector(".img4"), "fadeInRight");
      this.api.animateCSS(this.$el.querySelector(".img5"), "fadeInLeft");
      this.api.animateCSS(this.$el.querySelector(".img6"), "fadeInRight");
    }, 100);
  },
};
</script>
<style lang="less" scoped>
.com-home-QuestionOne {
  .img0 {
    margin-left: 100px;
    margin-top: 100px;
  }
  .img1 {
    width: 560px;
    margin-left: 160px;
    margin-top: 10px;
  }
  .img2 {
    margin-left: 350px;
    margin-top: 132px;
  }
  .img3 {
    width: 154px;
    margin-left: 160px;
    margin-top: 200px;
  }
  .img4 {
    width: 570px;
    margin-left: 195px;
    margin-top: 65px;
  }
  .img5 {
    width: 585px;
    margin-left: 120px;
    margin-top: 160px;
  }
  .img6 {
    width: 154px;
    margin-left: 570px;
    margin-top: 330px;
  }
  .img3,
  .img6 {
    --animate-duration: 1.5s;
  }
}
</style>